<!-- <div [ngClass]="{'forgot-password-translate-0': isShow}" class="forgot-password"
  id="forgot-password">
  <div class="forgot-password-container">

    <button (click)="Close()" class="page-remover border-0 p-1 back-btn"
      style="background-color: #210E78;">
      <img src="assets/images/cross.png" alt class="cross-img w-100">
    </button>

    <div class="forgot-password-content">
      <div class="row">
        <div class="col-md-6 pb-5">
          <div class="forgot-heading text-start mt-5">
            You forgot your <br> password? <br>
            I’d be glad to <br> retrieve it.
          </div>
          <div class="small-heading text-start mt-4 pb-2">
            Please enter your mail ID to <br> recieve a password reset link.
          </div>
          <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">

            <div class="email-box mt-5 mb-5">
              <input formControlName="email" type="email"
                placeholder="Enter your e-mail ID" class="w-100"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">Email must be valid</div>
              </div>
            </div>
            <div class="btn-container mb-3">
              <button type="submit" class="btnnn mb-3" style="cursor: pointer;"
                id="reset-linkk-toggle">
                Submit
              </button>
              <div (click)="Close()" class="cancel-btn">
                <span class="page-remover">
                  Cancel
                </span>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-6 dog-img ">
          <div class="bg-dog">
          </div>
          <img src="assets/images/dog.png" alt="DOGGY" class="w-100"
            class="DOGGY">
        </div>
      </div>
    </div>
  </div>
</div> -->

<div [ngClass]="{'forgot-password-translate-0': isShow}" class="new_login login"
  id="forgot-password">
  <div class="row m-0">
    <div
      class="col-lg-5 col-md-4 col-sm-12 col-12 d-lg-block d-md-block d-none px-0">
      <div class="login_dark">
      </div>
    </div>
    <div
      class="col-lg-7 col-md-8 col-sm-12 col-12 px-0 position-relative d-flex align-items-end">
      <div class="modal_close_button">
        <button (click)="Close()"
          class="page-remover border-0 bg-white p-0 back-btn">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
            viewBox="0 0 40 40" fill="none">
            <path
              d="M27.6451 12.3671C27.1718 11.8938 26.4073 11.8938 25.934 12.3671L20 18.289L14.066 12.3549C13.5927 11.8817 12.8282 11.8817 12.3549 12.3549C11.8817 12.8282 11.8817 13.5927 12.3549 14.066L18.289 20L12.3549 25.934C11.8817 26.4073 11.8817 27.1718 12.3549 27.6451C12.8282 28.1183 13.5927 28.1183 14.066 27.6451L20 21.711L25.934 27.6451C26.4073 28.1183 27.1718 28.1183 27.6451 27.6451C28.1183 27.1718 28.1183 26.4073 27.6451 25.934L21.711 20L27.6451 14.066C28.1062 13.6049 28.1062 12.8282 27.6451 12.3671Z"
              fill="#4527A0" />
          </svg>
        </button>
      </div>
      <div class="row justify-content-center m-0 w-100">
        <div class="col-lg-5 col-md-10 col-sm-12 col-12 pb-lg-5 pb-md-4 pb-3">
          <div class="login_form forgot_form position-relative pb-lg-5">
            <div class="forgot_detail mb-lg-5 pb-lg-5 mt-lg-0 mt-md-0 mt-5">
              <h2 class="mb-3">You forgot your password?<br/>
                I’d be glad to <span>retrieve</span> it.</h2>
                <p class="m-0">Please enter your mail ID to recieve a password reset link.</p>
            </div>
            <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
              <div class="email-box mt-0 mb-0 position-relative">
                <input formControlName="email" type="email"
                  placeholder="Enter your e-mail ID" class="w-100"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Email is required</div>
                  <div *ngIf="f.email.errors.email">Email must be valid</div>
                </div>
              </div>

              <div class="submit_button">
                <button class="btn btn-primary forgot_pwd mt-lg-4 mt-md-3 mt-3"
                  type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-5 col-md-2 col-sm-12 col-12 d-flex align-items-end">
          <div class="forgot_img">
            <img src="assets/images/new-home/dog.png" width="100%" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-resetpasswordlink (editedEmitter)="updateValueFromRestPasswordLink($event)"
  [isShow]="isShowRestPasswordLink">
</app-resetpasswordlink>
