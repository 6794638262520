<div class="paragraph-flexbox">
  <div class="user-profile-main-heading enterprise-plan-heading">
    Enterprise plan
  </div>
  <div class="validation-date">
    Valid till : <b> 11 October 2023 </b>
  </div>
</div>
<div class="plan-details-contianer">
  <div class="margin-bottom">
    <div class="size-20px-headings">Plan details</div>
    <div class="size-18px-lines">
      2 users, 20 employees, 2 locations <br>
      at $104.00 / month
    </div>
  </div>
  <div class="plans-date-and-type ">
    <table class="w-100 plan_table">
      <tr>
        <td colspan="2" class="size-22px-headings">
          Purchase details
        </td>
      </tr>
      <tr>
        <td class="size-16px-lines">Date of purchase :</td>
        <td>11 October 2022</td>
      </tr>
      <tr>
        <td class="size-16px-lines">Billing Type :</td>
        <td>Monthly subscription, <br> billed annually</td>
      </tr>
    </table>
  </div>
</div>

