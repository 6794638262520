<!-- reset password successfuly
<div class="main-background-cntner">
  <div class="right-column-reset-section-bg">
    <div class="left-column-reset-section">
      <div class="last-reset-section">
        <div class="padding-of-reset-section">
          <div class="brainy-nav-bar">
            <img src="img/Brainyclock logo.png" alt>
          </div>
          <div class="d-flex ">
            <div class="col-md-6 ">
              <div class="reset-successfully-heading">
                Password reset successfully!
              </div>
              <div class="buttons-for-mbl">
                <div (click)="showLogin()" class="goto-login-btn mb-4">
                  Go to login
                </div>
                <div [routerLink]="[ '/']" class="back-to-home-btn">
                  Back to home
                </div>
              </div>
            </div>
            <div
              class="col-md-6 login-box-pc-container d-flex justify-content-center">
              <form [formGroup]="resetSuccessFullloginForm"
                (ngSubmit)="onSubmit()">
                <div class="login-box-pc">
                  <div class="input-for-email"
                    style="width: 100% ; background: #F8F8F8;">
                    <div class="legend-heading">Email</div>
                    <input formControlName="email" type="email"
                      placeholder="Enter your E-mail ID"
                      [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    <div *ngIf="submitted && f.email.errors"
                      class="invalid-feedback">
                      <div *ngIf="f.email.errors.required">Email is required</div>
                      <div *ngIf="f.email.errors.email">Email must be valid</div>
                    </div>
                  </div>
                  <br>
                  <div class="input-for-password"
                    style="width: 100%;  background: #F8F8F8;">
                    <div class="legend-heading">Password</div>
                    <input formControlName="password" type="password"
                      placeholder="Type in your password"
                      [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors"
                      class="invalid-feedback">
                      <div *ngIf="f.password.errors.required">Password is
                        required</div>
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center mt-5">
                    <div class="remember d-flex align-items-center gap-3">
                      <div class="on-off-btn-box">
                        <div class="on-off-btn dot" id="dot">
                          <div class="on-off-dot toggle-btnn" id="toggle-btnn">&#160;</div>
                        </div>
                      </div>
                      <div>
                        Remember me
                      </div>
                    </div>
                    <div (click)="showForgotPassword(true)"
                      class="forgot forgot-password-toggle">
                      Forgot password?
                    </div>
                  </div>
                  <button type="submit" class="login-btn">
                    Log in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<section class="resertSuccessFull px-lg-5">
  <div class="container-fluid px-lg-3">
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 mb-lg-0 mb-md-4 mb-4">
        <div class="order_detail">
          <h2>Password reset successfully!</h2>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 col-sm-12 col-12">
        <div class="reset_form">
          <form [formGroup]="resetSuccessFullloginForm"
            (ngSubmit)="onSubmit()">
            <div class="input-for-email"
              style="width: 100% ; background: #F8F8F8;">
              <div class="legend-heading">Email</div>
              <input formControlName="email" type="email"
                placeholder="Enter your E-mail ID"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
              <div *ngIf="submitted && f.email.errors"
                class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">Email must be valid</div>
              </div>
            </div>
            <br>
            <div class="input-for-password position-relative"
              style="width: 100%;  background: #F8F8F8;">
              <div class="legend-heading">Password</div>
              <input formControlName="password" type="password"
                placeholder="Type in your password"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />

              <div class="password_icon_login paswword_login">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                  viewBox="0 0 40 40" fill="none"><g opacity="0.5">
                    <path
                      d="M19.7333 12C14.4 12 9.84533 15.3173 8 20C9.84533 24.6827 14.4 28 19.7333 28C25.0667 28 29.6213 24.6827 31.4667 20C29.6213 15.3173 25.0667 12 19.7333 12ZM19.7333 25.3333C16.7893 25.3333 14.4 22.944 14.4 20C14.4 17.056 16.7893 14.6667 19.7333 14.6667C22.6773 14.6667 25.0667 17.056 25.0667 20C25.0667 22.944 22.6773 25.3333 19.7333 25.3333ZM19.7333 16.8C17.9627 16.8 16.5333 18.2293 16.5333 20C16.5333 21.7707 17.9627 23.2 19.7333 23.2C21.504 23.2 22.9333 21.7707 22.9333 20C22.9333 18.2293 21.504 16.8 19.7333 16.8Z"
                      fill="#A6A6A6"></path></g></svg><svg
                  xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                  viewBox="0 0 40 40" fill="none" class="hide"><g opacity="0.5"><path
                      d="M19.7333 12C14.4 12 9.84533 15.3173 8 20C9.84533 24.6827 14.4 28 19.7333 28C25.0667 28 29.6213 24.6827 31.4667 20C29.6213 15.3173 25.0667 12 19.7333 12ZM19.7333 25.3333C16.7893 25.3333 14.4 22.944 14.4 20C14.4 17.056 16.7893 14.6667 19.7333 14.6667C22.6773 14.6667 25.0667 17.056 25.0667 20C25.0667 22.944 22.6773 25.3333 19.7333 25.3333ZM19.7333 16.8C17.9627 16.8 16.5333 18.2293 16.5333 20C16.5333 21.7707 17.9627 23.2 19.7333 23.2C21.504 23.2 22.9333 21.7707 22.9333 20C22.9333 18.2293 21.504 16.8 19.7333 16.8Z"
                      fill="#403D39"></path></g>
                </svg>
              </div>
              <div *ngIf="submitted && f.password.errors"
                class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is
                  required</div>
              </div>
            </div>
            <div
              class="d-flex justify-content-between align-items-center mt-lg-5 mt-md-4 mt-3">
              <div class="remember d-flex align-items-center gap-3">
                <div class="on-off-btn-box">
                  <div class="on-off-btn dot" id="dot">
                    <div class="on-off-dot toggle-btnn" id="toggle-btnn">&#160;</div>
                  </div>
                </div>
                <div>
                  Remember me
                </div>
              </div>
              <div (click)="showForgotPassword(true)"
                class="forgot forgot-password-toggle">
                Forgot password?
              </div>
            </div>
            <button type="submit"
              class="btn btn-primary mt-lg-5 mt-md-4 mt-4 pt-lg-3">
              Log in
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<app-forgotpassword (editedEmitter)="updateValueFromForgotPassword($event)"
  [isShow]="isShowForgotPassword">
</app-forgotpassword>
<app-login (editedEmitter)="updateValueFromLogin($event)" [isShow]="isShowLogin"></app-login>
