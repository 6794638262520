<section class="cyJmoW">
<section class="privay-policy"></section>
<div class="container-fluid privacy_policy p-0">
    <div class="content">
        <p class="main-title text-center">Privacy Policy For BrainyClock</p>
        <p class="sub-title text-center"><strong>Last updated March 13, 2024</strong></p>
        <div id="point-12">
            <div class="heading_1">Data Controller And Owner</div>
            <p class="mt-3 text-color fs-14">BrainyClock</p>
            <div class="mt-3 text-color fs-14">251 EAST 32ND STREET 7C</div>
            <div class=" text-color fs-14">New York, 10016</div>
            <p class=" text-color fs-14">United States</p>
            <p class="mt-3 text-color fs-14"><a href="mailto:info@brainyclock.com" target="_blank">info@brainyclock.com</a></p>
        </div>
        <hr>
        <div class="text-color fs-14">This privacy policy for BrainyClock (" <strong>BrainyClock </strong>", "<strong>we</strong>", "<strong>us</strong>" or "<strong>our</strong>"), describes how and why we might collect, store, use, and/or share ("<strong>process</strong>") your
            information when you use our services ("<strong>Services</strong>"), such as when you:
            <ul>
                <li>Download and use our mobile application (BrainyClock), or any other application of ours that links to this privacy policy</li>
                <li>Engage with us in other related ways, including any sales, marketing, or events</li>
            </ul>
            <p><strong><span class="text-color">Questions or concerns? </span></strong>Reading this privacy policy will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services.
                If you still have any questions or concerns, please contact us at <a href="mailto:info@brainyclock.com" target="_blank">info@brainyclock.com</a></p>
        </div>
    </div>
    <div class="summary">
        <div class="heading_1">SUMMARY OF KEY POINTS</div><span class="text-color fs-14"><strong>This summary provides key points from our privacy policy by using our table of contents below to find the section you are looking for.</strong></span>
        <ul>
            <li><strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with BrainyClock and the Services, the choices you make, and the products
                and features you use.</li>
            <li><strong>Do we process any sensitive personal information?</strong> We do not process sensitive personal information.</li>
            <li><strong>Do we receive any information from third parties?</strong> We do not receive any information from third parties.</li>
            <li>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process
                your information only when we have a valid legal reason to do so.</li>
            <li>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process
                your information only when we have a valid legal reason to do so.</li>
            <li><strong>In what situations and with which parties do we share personal information?</strong> We may share information in specific situations and with specific third parties.</li>
            <li>We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we
                cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.</li>
            <li><strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information.</li>
            <li><strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by filling out our data subject request by contacting us. We will consider and act upon any request in accordance with applicable data protection
                laws.</li>
        </ul>
    </div>
    <div id="table-contents">
        <div id="point-1">
            <div class="heading_1">1. WHAT INFORMATION DO WE COLLECT?</div>
            <p class="mt-3 text-color fs-14">We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or
                otherwise when you contact us.</p>
            <p class="mt-3 text-color fs-14"><strong>Personal Information Provided by You. </strong>The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal
                information we collect may include the following:
            </p>
                <ul>
                    <li>Email address</li>
                    <li>First name and last name</li>
                    <li>Age</li>
                    <li>Gender</li>
                </ul>
            <p class="mt-3 text-color fs-14"><strong>Sensitive Information.</strong>We do not process sensitive information.</p>
            <p class="mt-3 text-color fs-14">This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.</p>
            <p class="mt-3 text-color fs-14">All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>
        </div>
        <div id="point-2">
            <div class="heading_1">2. HOW DO WE PROCESS YOUR INFORMATION?</div>
            <p class="mt-3 text-color fs-14"><strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</strong>
            </p>
                <ul>
                    <li><strong>To facilitate account creation and authentication and otherwise manage user accounts.</strong> We may process your information so you can create and log in to your account, as well as keep your account in working order.</li>
                </ul>
        </div>
        <div id="point-3">
            <div class="heading_1">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</div>
            <p class="mt-3 text-color fs-14" style="text-decoration: underline;"><strong>If you are located in Canada, this section applies to you.</strong></p>
            <p class="mt-3 text-color fs-14">We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can
                withdraw your consent at any time.</p>
            <p class="mt-3 text-color fs-14">In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:
            </p>
                <ul>
                    <li>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</li>
                    <li>For investigations and fraud detection and prevention</li>
                    <li>For business transactions provided certain conditions are met</li>
                    <li>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</li>
                    <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
                    <li>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</li>
                    <li>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a
                        contravention of the laws of Canada or a province</li>
                    <li>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</li>
                    <li>If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</li>
                    <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
                    <li>If the information is publicly available and is specified by the regulations</li>
                </ul>
        </div>
        <div id="point-6">
            <div class="heading_1">4. HOW LONG DO WE KEEP YOUR INFORMATION?</div>
            <p class="mt-3 text-color fs-14">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).
                No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.</p>
        </div>
        <div id="point-7">
            <div class="heading_1">5. HOW DO WE KEEP YOUR INFORMATION SAFE?</div>
            <p class="mt-3 text-color fs-14">We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information,
                no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to
                defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk.
                You should only access the Services within a secure environment.</p>
        </div>
        <div id="point-8">
            <div class="heading_1">6. WHAT ARE YOUR PRIVACY RIGHTS?</div>
            <p class="mt-3 text-color fs-14">In some regions (like Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii)
                to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information.</p>
            <p class="mt-3 text-color fs-14">We will consider and act upon any request in accordance with applicable data protection laws.</p>
            <p class="mt-3 text-color fs-14">If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority.</p>
            <p class="mt-3 text-color fs-14">If you are located in Switzerland, the contact details for the data protection authorities are available here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html" target="blank">https://www.edoeb.admin.ch/edoeb/en/home.html.</a></p>
            <div
                class="heading_2">Account Information</div>
        <p class="mt-3 text-color fs-14">If you would at any time like to review or change the information in your account or terminate your account, you can:
        </p>
            <ul>
                <li>Log in to your account settings and update your user account.</li>
                <li>Contact us using the contact information provided.</li>
            </ul>
        <p class="mt-3 text-color fs-14">Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal requirements.</p>
        <p class="mt-3 text-color fs-14">If you have questions or comments about your privacy rights, you may email us at <a href="mailto:info@brainyclock.com" target="_blank">info@brainyclock.com</a>.</p>
    </div>
    <div id="point-10">
        <div class="heading_1">7. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</div>
        <p class="mt-3 text-color fs-14">California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information
            (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and
            would like to make such a request, please submit your request in writing to us using the contact information provided below.</p>
        <p class="mt-3 text-color fs-14">If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact
            us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware
            that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).</p>
        <div class="heading_2">CCPA Privacy Policy</div>
        <p class="mt-3 text-color fs-14">The California Code of Regulations defines a "resident" as:</p>
        <div class="mt-3 text-color fs-14">
            <ul>(1) every individual who is in the State of California for other than a temporary or transitory purpose and<br> (2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory
                purpose</ul>
            </div>
        <p class="mt-3 text-color fs-14">All other individuals are defined as "non-residents."</p>
        <p class="mt-3 text-color fs-14">If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.</p>
    </div>
    <div id="point-11">
        <div class="heading_1">8. DO WE MAKE UPDATES TO THIS NOTICE?</div>
        <p class="mt-3 text-color fs-14">We may update this privacy policy from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy,
            we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.</p>
    </div>
    <div id="point-13">
        <div class="heading_1">9. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</div>
        <p class="mt-3 text-color fs-14">Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. Also you can delete your account from application directly and then
            we will remove all your information from our database.</p>
    </div>.</div>
</div>

</section>