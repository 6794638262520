<!--reset password
 <div class="bg-body-container">
  <div class="brainy-nav-bar">
    <img src="assets/images/Brainyclock logo.png" alt>
  </div>
  <div class="  main-reset-container ">
    <div class="left-column-password">
      <p class="reset-pass-heading mb-5">Reset your password</p>

      <p class="surety-heading">Make sure you use :</p>

      <div class="password-set-rules">
        <p>✓ &#160;&#160;&#160;&#160;&#160; an upper case letter </p>
        <p>✓ &#160;&#160;&#160;&#160;&#160; a lower case lette</p>
        <p>✓ &#160;&#160;&#160;&#160;&#160; a number and a special character.</p>
      </div>
      <div class="pass-feilds ">
        <form [formGroup]="restPasswordForm" (ngSubmit)="onSubmit()">
          <div class="mb-4">
            <input formControlName="email" type="text"
              placeholder="Enter your email" class
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.email">Email must be valid</div>
            </div>
          </div>
          <div class="mb-4">
            <input formControlName="confirmationCode" type="text"
              placeholder="Enter code" class
              [ngClass]="{ 'is-invalid': submitted && f.confirmationCode.errors }" />
            <div *ngIf="submitted && f.confirmationCode.errors"
              class="invalid-feedback">
              <div *ngIf="f.confirmationCode.errors.required">Confirmation Code
                is required</div>
            </div>
          </div>
          <div class="mb-4">
            <input formControlName="password" type="password"
              placeholder="Enter new password" class="enter-pass"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />

            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
              <div *ngIf="f.password.errors.pattern">Password must be valid</div>
            </div>
          </div>
          <div class="mb-5">
            <input formControlName="confirmPassword" type="password"
              placeholder="Re-enter password"
              [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
            <div *ngIf="submitted && f.confirmPassword.errors"
              class="invalid-feedback">
              <div *ngIf="f.confirmPassword.errors.required">Confirm Password is
                required</div>
              <div *ngIf="f.confirmPassword.errors.pattern">Confirm Password
                must be valid</div>
              <div *ngIf="f.confirmPassword.errors.mustMatch">Password must be
                match</div>
            </div>
          </div>
          <div (click)="onSubmit()" type="submit" class="sub-btn mb-3">
            Reset Password
          </div>
        </form>

      </div>

    </div>
    <div class="right-column-background">
      <img src="assets/images/lock.png" alt>
    </div>

  </div> -->

  <section class="reset-password">
    <div class="container-fluid">
      <div class="row flex-lg-row flex-md-column-reverse flex-column-reverse">
        <div class="col-lg-3 col-md-12 col-sm-12 col-12 d-flex align-items-center offset-lg-1">
          <div class="reset_pwd_option w-100">
            <h2>Reset your password</h2>
            <p class="mb-0 mt-3">Make sure you use :</p>
            <ul class="m-0 p-0">
              <li>
                <span class="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none">
                    <path
                      d="M18.7099 7.21C18.617 7.11627 18.5064 7.04188 18.3845 6.99111C18.2627 6.94034 18.132 6.9142 17.9999 6.9142C17.8679 6.9142 17.7372 6.94034 17.6154 6.99111C17.4935 7.04188 17.3829 7.11627 17.29 7.21L9.83995 14.67L6.70995 11.53C6.61343 11.4368 6.49949 11.3634 6.37463 11.3142C6.24978 11.265 6.11645 11.2409 5.98227 11.2432C5.84809 11.2456 5.71568 11.2743 5.5926 11.3278C5.46953 11.3813 5.35819 11.4585 5.26495 11.555C5.17171 11.6515 5.0984 11.7655 5.04919 11.8903C4.99999 12.0152 4.97586 12.1485 4.97818 12.2827C4.9805 12.4169 5.00923 12.5493 5.06272 12.6723C5.11622 12.7954 5.19343 12.9068 5.28995 13L9.12995 16.84C9.22291 16.9337 9.33351 17.0081 9.45537 17.0589C9.57723 17.1097 9.70794 17.1358 9.83995 17.1358C9.97196 17.1358 10.1027 17.1097 10.2245 17.0589C10.3464 17.0081 10.457 16.9337 10.55 16.84L18.7099 8.68C18.8115 8.58636 18.8925 8.4727 18.9479 8.3462C19.0033 8.21971 19.0319 8.0831 19.0319 7.945C19.0319 7.8069 19.0033 7.67029 18.9479 7.54379C18.8925 7.41729 18.8115 7.30364 18.7099 7.21Z"
                      fill="#403D39" />
                  </svg>
                </span>
                an upper case letter</li>
              <li>
                <span class="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none">
                    <path
                      d="M18.7099 7.21C18.617 7.11627 18.5064 7.04188 18.3845 6.99111C18.2627 6.94034 18.132 6.9142 17.9999 6.9142C17.8679 6.9142 17.7372 6.94034 17.6154 6.99111C17.4935 7.04188 17.3829 7.11627 17.29 7.21L9.83995 14.67L6.70995 11.53C6.61343 11.4368 6.49949 11.3634 6.37463 11.3142C6.24978 11.265 6.11645 11.2409 5.98227 11.2432C5.84809 11.2456 5.71568 11.2743 5.5926 11.3278C5.46953 11.3813 5.35819 11.4585 5.26495 11.555C5.17171 11.6515 5.0984 11.7655 5.04919 11.8903C4.99999 12.0152 4.97586 12.1485 4.97818 12.2827C4.9805 12.4169 5.00923 12.5493 5.06272 12.6723C5.11622 12.7954 5.19343 12.9068 5.28995 13L9.12995 16.84C9.22291 16.9337 9.33351 17.0081 9.45537 17.0589C9.57723 17.1097 9.70794 17.1358 9.83995 17.1358C9.97196 17.1358 10.1027 17.1097 10.2245 17.0589C10.3464 17.0081 10.457 16.9337 10.55 16.84L18.7099 8.68C18.8115 8.58636 18.8925 8.4727 18.9479 8.3462C19.0033 8.21971 19.0319 8.0831 19.0319 7.945C19.0319 7.8069 19.0033 7.67029 18.9479 7.54379C18.8925 7.41729 18.8115 7.30364 18.7099 7.21Z"
                      fill="#403D39" />
                  </svg>
                </span>
                a lower case letter</li>
              <li>
                <span class="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none">
                    <path
                      d="M18.7099 7.21C18.617 7.11627 18.5064 7.04188 18.3845 6.99111C18.2627 6.94034 18.132 6.9142 17.9999 6.9142C17.8679 6.9142 17.7372 6.94034 17.6154 6.99111C17.4935 7.04188 17.3829 7.11627 17.29 7.21L9.83995 14.67L6.70995 11.53C6.61343 11.4368 6.49949 11.3634 6.37463 11.3142C6.24978 11.265 6.11645 11.2409 5.98227 11.2432C5.84809 11.2456 5.71568 11.2743 5.5926 11.3278C5.46953 11.3813 5.35819 11.4585 5.26495 11.555C5.17171 11.6515 5.0984 11.7655 5.04919 11.8903C4.99999 12.0152 4.97586 12.1485 4.97818 12.2827C4.9805 12.4169 5.00923 12.5493 5.06272 12.6723C5.11622 12.7954 5.19343 12.9068 5.28995 13L9.12995 16.84C9.22291 16.9337 9.33351 17.0081 9.45537 17.0589C9.57723 17.1097 9.70794 17.1358 9.83995 17.1358C9.97196 17.1358 10.1027 17.1097 10.2245 17.0589C10.3464 17.0081 10.457 16.9337 10.55 16.84L18.7099 8.68C18.8115 8.58636 18.8925 8.4727 18.9479 8.3462C19.0033 8.21971 19.0319 8.0831 19.0319 7.945C19.0319 7.8069 19.0033 7.67029 18.9479 7.54379C18.8925 7.41729 18.8115 7.30364 18.7099 7.21Z"
                      fill="#403D39" />
                  </svg>
                </span>
                a number and a special character.</li>
            </ul>

            <form action class="payment_detail change_password mt-lg-5 mt-md-4 mt-4">
              <div class="form d-flex align-items-center mb-3">
                <div class="w-100 position-relative">
                  <input type="password" placeholder="Enter new password"
                    id="current_password">
                  <div class="eye_icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="45"
                      height="40" viewBox="0 0 45 40" fill="none">
                      <g opacity="0.5">
                        <path
                          d="M22.2 12C16.2 12 11.076 15.3173 9 20C11.076 24.6827 16.2 28 22.2 28C28.2 28 33.324 24.6827 35.4 20C33.324 15.3173 28.2 12 22.2 12ZM22.2 25.3333C18.888 25.3333 16.2 22.944 16.2 20C16.2 17.056 18.888 14.6667 22.2 14.6667C25.512 14.6667 28.2 17.056 28.2 20C28.2 22.944 25.512 25.3333 22.2 25.3333ZM22.2 16.8C20.208 16.8 18.6 18.2293 18.6 20C18.6 21.7707 20.208 23.2 22.2 23.2C24.192 23.2 25.8 21.7707 25.8 20C25.8 18.2293 24.192 16.8 22.2 16.8Z"
                          fill="#403D39" />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div class="form d-flex align-items-center">
                <div class="w-100 position-relative">
                  <input type="password" placeholder="Re-enter password"
                    id="current_password">
                  <div class="eye_icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="45"
                      height="40" viewBox="0 0 45 40" fill="none">
                      <g opacity="0.5">
                        <path
                          d="M22.2 12C16.2 12 11.076 15.3173 9 20C11.076 24.6827 16.2 28 22.2 28C28.2 28 33.324 24.6827 35.4 20C33.324 15.3173 28.2 12 22.2 12ZM22.2 25.3333C18.888 25.3333 16.2 22.944 16.2 20C16.2 17.056 18.888 14.6667 22.2 14.6667C25.512 14.6667 28.2 17.056 28.2 20C28.2 22.944 25.512 25.3333 22.2 25.3333ZM22.2 16.8C20.208 16.8 18.6 18.2293 18.6 20C18.6 21.7707 20.208 23.2 22.2 23.2C24.192 23.2 25.8 21.7707 25.8 20C25.8 18.2293 24.192 16.8 22.2 16.8Z"
                          fill="#403D39" />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div class="form d-flex align-items-center mt-4 pt-lg-3">
                <button class="btn btn-primary">Reset
                  password</button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="lock_pwd">
            <img src="assets/images/new-home/lock.png" width="100%" alt="">
          </div>
        </div>
      </div>
    </div>
  </section>