<div [ngClass]="{'sign-up-translate-0': isShow}" class="new_login sign-up login"
  id="sign-up" class="new_login login"
  id="login">
  <div class="row m-0">
    <div
      class="col-lg-5 col-md-4 col-sm-12 col-12 d-lg-block d-md-block d-none px-0">
      <div class="login_dark">
      </div>
    </div>
    <div
      class="col-lg-7 col-md-8 col-sm-12 col-12 px-0 position-relative d-flex align-items-center">
      <div class="modal_close_button">
        <button (click)="Close()"
          class="page-remover border-0 bg-white p-0 back-btn">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
            viewBox="0 0 40 40" fill="none">
            <path
              d="M27.6451 12.3671C27.1718 11.8938 26.4073 11.8938 25.934 12.3671L20 18.289L14.066 12.3549C13.5927 11.8817 12.8282 11.8817 12.3549 12.3549C11.8817 12.8282 11.8817 13.5927 12.3549 14.066L18.289 20L12.3549 25.934C11.8817 26.4073 11.8817 27.1718 12.3549 27.6451C12.8282 28.1183 13.5927 28.1183 14.066 27.6451L20 21.711L25.934 27.6451C26.4073 28.1183 27.1718 28.1183 27.6451 27.6451C28.1183 27.1718 28.1183 26.4073 27.6451 25.934L21.711 20L27.6451 14.066C28.1062 13.6049 28.1062 12.8282 27.6451 12.3671Z"
              fill="#4527A0" />
          </svg>
        </button>
      </div>
      <div class="row justify-content-start m-0 w-100 ps-lg-4">
        <div
          class="col-lg-4 col-md-12 col-sm-12 col-12 ps-lg-2 d-flex align-items-center mt-lg-0 mt-md-0 mt-5">
          <div class="sign_up_page_title">
            <!-- <h2>{{ 'signupData.sign_up_page_title' | translate }}</h2> -->
            <h2>   {{ 'signup.sign_up_page_title' | translate }}</h2>
          </div>
        </div>
        <div class="col-lg-6 col-md-10 col-sm-12 col-12">
          <h2 class="login-header login_outer m-0">
            {{ 'signup.heading' | translate }}
          </h2>
          <div class="sign_up_input position-relative">
            <form [formGroup]="signupForm" class="mt-lg-4 mt-lg-4 mt-3"
              (ngSubmit)="onSubmit()">
              <div class="input-div mb-lg-4 mb-md-3 mb-2">
                <label for>  {{ 'signup.firstNameLabel' | translate }}</label>
                <input formControlName="firstName" class="w-100" type="text"
                  placeholder="John"
                  [placeholder]="'signup.firstNamePlaceholder' | translate"
                  [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                <div *ngIf="submitted && f.firstName.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.firstName.errors.required">{{ 'signup.invalidFirstNameMessage' | translate }}</div>
                </div>
              </div>
              <div class="input-div mb-lg-4 mb-md-3 mb-2">
                <label for> {{ 'signup.lastNameLabel' | translate }}</label>
                <input formControlName="lastName" class="w-100" type="text"
                [placeholder]="'signup.lastNamePlaceholder' | translate"
                  [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                <div *ngIf="submitted && f.lastName.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.lastName.errors.required"> {{ 'signup.invalidLastNameMessage' | translate }}</div>
                </div>
              </div>
              <div class="input-div mb-lg-4 mb-md-3 mb-2">
                <label for> {{ 'signup.companyNameLabel' | translate }}</label>
                <input formControlName="company_name" class="w-100" type="text"
                [placeholder]="'signup.companyNamePlaceholder' | translate"
                  [ngClass]="{ 'is-invalid': submitted && f.company_name.errors }" />
                <div *ngIf="submitted && f.company_name.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.company_name.errors.required"> {{ 'signup.invalidCompanyNameMessage' | translate }}</div>
                </div>
              </div>
              <div class="input-div mb-lg-4 mb-md-3 mb-2">
                <label for>{{ 'signup.emailLabel' | translate }}</label>
                <input formControlName="email" class="w-100" type="email"  [placeholder]="'signup.emailPlaceholder' | translate"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">{{ 'signup.invalidEmailMessage' | translate }}</div>
                  <div *ngIf="f.email.errors.email">{{ 'signup.invalidEmailFormatMessage' | translate }}</div>
                </div>
              </div>
              <div class="input-div mb-0">
                <label for>{{ 'signup.passwordLabel' | translate }}</label>
                <input formControlName="password" class="w-100 mb-3"
                  [type]="showPassword ? 'text' : 'password'"
                  [placeholder]="'signup.passwordPlaceholder' | translate"
                  [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                  <div *ngIf="submitted && f.password.errors"
                    class="invalid-feedback pswword_validation">
                    <div *ngIf="f.password.errors.required">{{ 'signup.invalidPasswordMessage' | translate }}</div>
                    <div *ngIf="f.password.errors.pattern">{{ 'signup.invalidPasswordPatternMessage' | translate }}</div>
                  </div>
                  <div class="password_icon_login paswword_login">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40"
                      height="40" viewBox="0 0 40 40" fill="none"  (click)="showPassword = !showPassword"
                      [class.hide]="showPassword">
                      <g opacity="0.5">
                        <path
                          d="M19.7333 12C14.4 12 9.84533 15.3173 8 20C9.84533 24.6827 14.4 28 19.7333 28C25.0667 28 29.6213 24.6827 31.4667 20C29.6213 15.3173 25.0667 12 19.7333 12ZM19.7333 25.3333C16.7893 25.3333 14.4 22.944 14.4 20C14.4 17.056 16.7893 14.6667 19.7333 14.6667C22.6773 14.6667 25.0667 17.056 25.0667 20C25.0667 22.944 22.6773 25.3333 19.7333 25.3333ZM19.7333 16.8C17.9627 16.8 16.5333 18.2293 16.5333 20C16.5333 21.7707 17.9627 23.2 19.7333 23.2C21.504 23.2 22.9333 21.7707 22.9333 20C22.9333 18.2293 21.504 16.8 19.7333 16.8Z"
                          fill="#A6A6A6" />
                      </g>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40"
                    height="40" viewBox="0 0 40 40" fill="none"
                    (click)="showPassword = !showPassword"
                    [class.hide]="!showPassword">
                    <g opacity="0.5">
                      <path
                        d="M19.7333 12C14.4 12 9.84533 15.3173 8 20C9.84533 24.6827 14.4 28 19.7333 28C25.0667 28 29.6213 24.6827 31.4667 20C29.6213 15.3173 25.0667 12 19.7333 12ZM19.7333 25.3333C16.7893 25.3333 14.4 22.944 14.4 20C14.4 17.056 16.7893 14.6667 19.7333 14.6667C22.6773 14.6667 25.0667 17.056 25.0667 20C25.0667 22.944 22.6773 25.3333 19.7333 25.3333ZM19.7333 16.8C17.9627 16.8 16.5333 18.2293 16.5333 20C16.5333 21.7707 17.9627 23.2 19.7333 23.2C21.504 23.2 22.9333 21.7707 22.9333 20C22.9333 18.2293 21.504 16.8 19.7333 16.8Z"
                        fill="#403D39" />
                    </g>
                  </svg>
                  </div>
                <p class="pass-disc">
                  {{ 'signup.passwordRequirements' | translate }}
                </p>
              </div>
              <div class="input-div mb-0">
                <label for> {{ 'signup.confirmPasswordLabel' | translate }}</label>
                <input formControlName="confirmPassword" class="w-100"
                [type]="showConfirmPassword ? 'text' : 'password'"
                [placeholder]="'signup.confirmPasswordPlaceholder' | translate"
                  [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
                <div class="password_icon_login paswword_login">
                  <svg xmlns="http://www.w3.org/2000/svg" width="40"
                    height="40" viewBox="0 0 40 40" fill="none" (click)="showConfirmPassword = !showConfirmPassword"
                    [class.hide]="showConfirmPassword">
                    <g opacity="0.5">
                      <path d="M19.7333 12C14.4 12 9.84533 15.3173 8 20C9.84533 24.6827 14.4 28 19.7333 28C25.0667 28 29.6213 24.6827 31.4667 20C29.6213 15.3173 25.0667 12 19.7333 12ZM19.7333 25.3333C16.7893 25.3333 14.4 22.944 14.4 20C14.4 17.056 16.7893 14.6667 19.7333 14.6667C22.6773 14.6667 25.0667 17.056 25.0667 20C25.0667 22.944 22.6773 25.3333 19.7333 25.3333ZM19.7333 16.8C17.9627 16.8 16.5333 18.2293 16.5333 20C16.5333 21.7707 17.9627 23.2 19.7333 23.2C21.504 23.2 22.9333 21.7707 22.9333 20C22.9333 18.2293 21.504 16.8 19.7333 16.8Z"
                        fill="#A6A6A6" />
                    </g>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="40"
                  height="40" viewBox="0 0 40 40" fill="none"
                  (click)="showConfirmPassword = !showConfirmPassword"
                  [class.hide]="!showConfirmPassword">
                  <g opacity="0.5">
                   <path d="M19.7333 12C14.4 12 9.84533 15.3173 8 20C9.84533 24.6827 14.4 28 19.7333 28C25.0667 28 29.6213 24.6827 31.4667 20C29.6213 15.3173 25.0667 12 19.7333 12ZM19.7333 25.3333C16.7893 25.3333 14.4 22.944 14.4 20C14.4 17.056 16.7893 14.6667 19.7333 14.6667C22.6773 14.6667 25.0667 17.056 25.0667 20C25.0667 22.944 22.6773 25.3333 19.7333 25.3333ZM19.7333 16.8C17.9627 16.8 16.5333 18.2293 16.5333 20C16.5333 21.7707 17.9627 23.2 19.7333 23.2C21.504 23.2 22.9333 21.7707 22.9333 20C22.9333 18.2293 21.504 16.8 19.7333 16.8Z"
                    fill="#403D39" />
                  </g>
                </svg>
                </div>
                <div *ngIf="submitted && f.confirmPassword.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.confirmPassword.errors.required"> {{ 'signup.invalidConfirmPasswordMessage' | translate }}</div>
                  <div *ngIf="f.confirmPassword.errors.pattern"> {{ 'signup.invalidConfirmPasswordPatternMessage' | translate }}</div>
                  <div *ngIf="f.confirmPassword.errors.mustMatch"> {{ 'signup.passwordsMustMatchMessage' | translate }}</div>
                </div>
              </div>
              <div class="submit_button pt-lg-3">
                <button class="btn btn-primary mt-lg-5 mt-md-4 mt-3"
                  type="submit">{{ 'signup.signupButton' | translate }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
