<div [ngClass]="{'contact-us-translate-0': isShow}"
  class="contact-us new_contact"
  id="contact-us">
  <div class="row m-0">
    <div
      class="col-lg-5 col-md-4 col-sm-12 col-12 d-lg-block d-md-block d-none px-0">
      <div class="login_dark">
      </div>
    </div>
    <div
      class="col-lg-7 col-md-8 col-sm-12 col-12 px-0 position-relative d-flex align-items-center">
      <div class="modal_close_button">
        <button (click)="Close()"
          class="page-remover border-0 bg-white p-0 back-btn">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
            viewBox="0 0 40 40" fill="none">
            <path
              d="M27.6451 12.3671C27.1718 11.8938 26.4073 11.8938 25.934 12.3671L20 18.289L14.066 12.3549C13.5927 11.8817 12.8282 11.8817 12.3549 12.3549C11.8817 12.8282 11.8817 13.5927 12.3549 14.066L18.289 20L12.3549 25.934C11.8817 26.4073 11.8817 27.1718 12.3549 27.6451C12.8282 28.1183 13.5927 28.1183 14.066 27.6451L20 21.711L25.934 27.6451C26.4073 28.1183 27.1718 28.1183 27.6451 27.6451C28.1183 27.1718 28.1183 26.4073 27.6451 25.934L21.711 20L27.6451 14.066C28.1062 13.6049 28.1062 12.8282 27.6451 12.3671Z"
              fill="#4527A0" />
          </svg>
        </button>
      </div>
      <div class="row justify-content-center m-0 w-100 pt-lg-0 pt-md-0 pt-5">
        <div
          class="col-lg-6 col-md-12 offset-lg-1 col-sm-12 col-12 mb-4 pb-lg-1">
          <div class="contact_header">
            <h2>     {{ 'contactUs.heading' | translate }}</h2>
          </div>
        </div>
        <div
          class="col-lg-5 col-md-12 col-sm-12 col-12 d-lg-block d-md-none d-none mb-4 pb-lg-1">

        </div>
        <div
          class="col-lg-5 col-md-12 col-sm-12 col-12 d-flex align-items-center pb-lg-5">
          <div class="contact_info">
            <ul>
              <li class="mb-lg-5 pb-lg-2 mb-md-4 mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16"
                  viewBox="0 0 20 16" fill="none">
                  <path
                    d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V13C0 13.7956 0.316071 14.5587 0.87868 15.1213C1.44129 15.6839 2.20435 16 3 16H17C17.7956 16 18.5587 15.6839 19.1213 15.1213C19.6839 14.5587 20 13.7956 20 13V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM16.59 2L10.71 7.88C10.617 7.97373 10.5064 8.04812 10.3846 8.09889C10.2627 8.14966 10.132 8.1758 10 8.1758C9.86799 8.1758 9.73728 8.14966 9.61542 8.09889C9.49356 8.04812 9.38296 7.97373 9.29 7.88L3.41 2H16.59ZM18 13C18 13.2652 17.8946 13.5196 17.7071 13.7071C17.5196 13.8946 17.2652 14 17 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V3.41L7.88 9.29C8.4425 9.8518 9.205 10.1674 10 10.1674C10.795 10.1674 11.5575 9.8518 12.12 9.29L18 3.41V13Z"
                    fill="#EB5E28" />
                </svg>
                <span>{{ 'contactUs.email' | translate }}</span>
              </li>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none">
                  <path
                    d="M12 2C9.87827 2 7.84344 2.84285 6.34315 4.34315C4.84285 5.84344 4 7.87827 4 10C4 15.4 11.05 21.5 11.35 21.76C11.5311 21.9149 11.7616 22.0001 12 22.0001C12.2384 22.0001 12.4689 21.9149 12.65 21.76C13 21.5 20 15.4 20 10C20 7.87827 19.1571 5.84344 17.6569 4.34315C16.1566 2.84285 14.1217 2 12 2ZM12 19.65C9.87 17.65 6 13.34 6 10C6 8.4087 6.63214 6.88258 7.75736 5.75736C8.88258 4.63214 10.4087 4 12 4C13.5913 4 15.1174 4.63214 16.2426 5.75736C17.3679 6.88258 18 8.4087 18 10C18 13.34 14.13 17.66 12 19.65ZM12 6C11.2089 6 10.4355 6.2346 9.77772 6.67412C9.11992 7.11365 8.60723 7.73836 8.30448 8.46927C8.00173 9.20017 7.92252 10.0044 8.07686 10.7804C8.2312 11.5563 8.61216 12.269 9.17157 12.8284C9.73098 13.3878 10.4437 13.7688 11.2196 13.9231C11.9956 14.0775 12.7998 13.9983 13.5307 13.6955C14.2616 13.3928 14.8864 12.8801 15.3259 12.2223C15.7654 11.5645 16 10.7911 16 10C16 8.93913 15.5786 7.92172 14.8284 7.17157C14.0783 6.42143 13.0609 6 12 6ZM12 12C11.6044 12 11.2178 11.8827 10.8889 11.6629C10.56 11.4432 10.3036 11.1308 10.1522 10.7654C10.0009 10.3999 9.96126 9.99778 10.0384 9.60982C10.1156 9.22186 10.3061 8.86549 10.5858 8.58579C10.8655 8.30608 11.2219 8.1156 11.6098 8.03843C11.9978 7.96126 12.3999 8.00087 12.7654 8.15224C13.1308 8.30362 13.4432 8.55996 13.6629 8.88886C13.8827 9.21776 14 9.60444 14 10C14 10.5304 13.7893 11.0391 13.4142 11.4142C13.0391 11.7893 12.5304 12 12 12Z"
                    fill="#EB5E28" />
                </svg>
                <span>{{ 'contactUs.address' | translate }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-5 col-md-10 offset-lg-1 col-sm-12 col-12 mt-lg-0 mt-md-4 mt-4">
          <div class="login_form login_error position-relative">
            <form [formGroup]="contactForm" (ngSubmit)="onSubmit()"
              class="contact-form">
              <div class="position-relative">
                <input formControlName="name" type="text"
                [placeholder]="'contactUs.namePlaceholder' | translate"
                  [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                  <div *ngIf="f.name.errors.required">{{ 'contactUs.invalidNameMessage' | translate }}</div>
                </div>
              </div>
              <div class="position-relative">
                <input formControlName="email" type="text"
                [placeholder]="'contactUs.emailPlaceholder' | translate"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">{{ 'contactUs.invalidNameMessage' | translate }}</div>
                  <div *ngIf="f.email.errors.email">{{ 'contactUs.invalidEmailFormatMessage' | translate }}</div>
                </div>
              </div>
              <div class="position-relative">
                <input formControlName="organization" type="text"
                [placeholder]="'contactUs.organizationPlaceholder' | translate"
                  [ngClass]="{ 'is-invalid': submitted && f.organization.errors }" />
                <div *ngIf="submitted && f.organization.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.organization.errors.required">{{'contactUs.invalidOrganizationMessage' | translate }}</div>
                </div>
              </div>
              <div class="position-relative">
                <textarea formControlName="message"
                 [placeholder]="'contactUs.messagePlaceholder' | translate"
                name
                  id cols="30" rows="10"
                  [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
                <div *ngIf="submitted && f.message.errors"
                  class="invalid-feedback text-area">
                  <div *ngIf="f.message.errors.required">{{'contactUs.invalidMessage' | translate }}</div>
                </div>
              </div>
              <div class="submit_button d-flex justify-content-end">
                <button type="submit"
                  class="btn btn-primary forgot_pwd mt-lg-4 mt-md-3 mt-3">{{'contactUs.submitButton' | translate }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>