import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mysubscriptions',
  templateUrl: './mysubscriptions.component.html',
  styleUrls: ['./mysubscriptions.component.css']
})
export class MysubscriptionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
