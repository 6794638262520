<div [ngClass]="{'reset-linkk-translate-0': isShow}" class="reset-linkk" id="reset-linkk">
  <div class="bg-color pt-3">
      <div (click)="Close()" class="cross" >
          <img src="assets/images/cross.png" alt="" class="page-remover">
      </div>
      <div class="container">
          <div class="reset-link-content">
              <p class="mt-5 mb-5 rese-link-heading">Password reset <br class="on-pc"> <br class="on-mbl"> link has been <br class="on-mbl"> sent <br class="on-pc"> to your <br class="on-mbl"> email.</p>
          <p class="reset-time mb-5">You can use the link to <br class="on-mbl"> change <br class="on-pc"> your password, within <br class="on-mbl"> the next <br class="on-pc"> 24 hours.</p>
          <a (click)="Close()">
              <div class="home-btn">
                  Back to home
              </div>
            </a>
          </div>
      </div>
  </div>
</div>
