<div>
    <div class="Transactionhsitory" style="padding-right:40px;">
      <div class="user-profile-main-heading">
        Transaction history
      </div>

      <div class="black-border mt-5">

        <!-- <img src="assets/images/drop-up.png" alt="" class="up-arrow-drop"> -->

        <div class="enterprisee">
          <div class="pllan zargar">
            <div>
              Enterprise plan
            </div>
            <div class="month">
              $104.00 / month
            </div>
          </div>
          <div class="Orrder zargar">
            <div class="weight">
              Order number :
            </div>
            <div class="cpkl">
              CPKL234PRE12234
            </div>
          </div>
        </div>
        <div class="userss">
          2 users, 20 employees, 2 locations
        </div>
        <div class="purchasee">
          <div class="zargar">
            <div class="weight">
              Date of purchase :
            </div>
            <div>
              11 October 2022
            </div>
          </div>
          <div class="zargar">
            <div class="weight">
              Order total :
            </div>
            <div>
              $1248.00
            </div>
          </div>
          <div class="zargar">
            <div class="weight">
              Billing type :
            </div>
            <div>
              Annual subscription
            </div>
          </div>
          <div class="zargar">
            <div class="weight">
              Payment method :
            </div>
            <div>
              Credit card
            </div>
          </div>

        </div>
        <div class="bbutton-container mt-5">
          <button class="btnn contact-btn">Buy again</button>
        </div>
      </div>
    </div>
  </div>
