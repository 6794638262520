<div class="mycontainer">
  <nav class="d-flex align-items-center justify-content-between">
    <div class="logo">
      <a [routerLink]="[ '/']">
        <img src="assets/images/brainy-logo.png" alt="" class="logo-on-mbl"><img
          src="assets/images/brainyclock-logo.png" alt="" class="logo-on-pc">
      </a>
    </div>
  </nav>
  <div class="inner-container">
    <div class="trial-container">
      <div>
        <div class="row justify-content-between">
          <div class="col-md-6 col-12">
            <div>
              <p class="trial-header">
                Start your free trial, <br> today!
              </p>
              <p class="trial-disc">
                Try Brainyclock for a month before you decide <br> to upgrade to one of our premium plans.
              </p>

              <div class="hidden-buttons text-center">
                <button (click)="showLogin()" class="login-button login-toggle">
                  login
                </button>
                <div>
                  <img src="assets/images/google.png" alt="" width="325px" class="google-img">
                </div>
              </div>

              <div class="note">
                <span class="mb-3">Note :</span> <br>
                Paid subscription starts when the trial ends, unless you cancel by 11/11/2022. <br>
                Credit card required for future billing. <br>
                Trial includes same features as the paid product.
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="login-container bg-white px-4">

              <img src="assets/images/google.png" alt="Conitnue with google" class="w-100">
              <div class="option d-flex justify-content-between align-items-center">
                <div class="w-25">
                  <img src="assets/images/line.png" alt="" class="w-100">
                </div>
                <p class="text-center mb-0 w-75">
                  Or log in with you email-ID
                </p>
                <div class="w-25">
                  <img src="assets/images/line.png" alt="" class="w-100">
                </div>
              </div>

              <form [formGroup]="trybeforebuyForm" (ngSubmit)="onSubmit()">
                <div class="input-div">
                  <label for="">Email</label>
                  <input formControlName="email" class="w-100" type="email" placeholder="Email enter your email-ID"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.email">Email must be valid</div>
                  </div>
                </div>
                <div class="input-div" >
                  <label for="">Password</label>
                  <div class="password-container position-relative">
                  <input formControlName="password" class="w-100" [type]="showPassword ? 'text' : 'password'"
                  placeholder="Type in your password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div class="password_icon_login" >
                      <img src="../../../assets/images/password.png" alt="" (click)="showPassword = !showPassword"
                        [class.hide]="showPassword" />
                      <img src="../../../assets/images/password.png" alt="" (click)="showPassword = !showPassword"
                        [class.hide]="!showPassword" />
                    </div>
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                    <div *ngIf="f.password.errors.pattern">Password must be valid</div>
                  </div>
                </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="remember d-flex align-items-center gap-1">
                    <div class="on-off-btn-box">
                      <div class="on-off-btn dot" id="dot">
                        <div class="on-off-dot toggle-btnn" id="toggle-btnn">&#160;</div>
                      </div>
                    </div>
                    <div>
                      Remember me
                    </div>
                  </div>
                  <div (click)="showForgotPassword(true)" class="forgot forgot-password-toggle"
                    id="forgot-password-toggle">
                    Forgot password?
                  </div>
                </div>
                <button type="submit" class="login-btn">
                  Log in
                </button>

                <div class="d-flex justify-content-around mt-3">
                  <p class="register">Not registered yet?
                  <p>
                  <p (click)="showSignup(true)" class="sign-upp sign-up-toggle" id="sign-upp-toggle"
                    style="cursor: pointer;">Sign up here</p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-forgotpassword (editedEmitter)="updateValueFromForgotPassword($event)" [isShow]="isShowForgotPassword">
</app-forgotpassword>
<app-signup (editedEmitter)="updateValueSignup($event)" [isShow]="isShowSignup"></app-signup>
<app-login (editedEmitter)="updateValueFromLogin($event)" [isShow]="isShowLogin"></app-login>
