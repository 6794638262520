<form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
  <div class="pass-change-fields">
    <div class="user-profile-main-heading">
      Change password
    </div>
    <div class="mt-5 ">
      <div class="row mb-4 ">
        <div class="col-md-5 col-12 pass-fields-lebal">Enter current password :</div>
        <div class="col-md-7 col-12 ">
          <div class="password-container position-relative">
            <input [type]="showPassword ? 'text' : 'password'" formControlName="currentPassword"
              placeholder="type in your password" class="ch-nge-pass-input"
              [ngClass]="{ 'is-invalid': submitted && f.currentPassword.errors }">
            <div class="password_icon">
              <img src="../../../assets/images/hide-password.png" alt="" (click)="showPassword = !showPassword"
                [class.hide]="showPassword" />
              <img src="../../../assets/images/password.png" alt="" (click)="showPassword = !showPassword"
                [class.hide]="!showPassword" />
            </div>
            <div *ngIf="submitted && f.currentPassword.errors" class="invalid-feedback">
              <div *ngIf="f.currentPassword.errors.required">Current Password is required</div>
              <div *ngIf="f.currentPassword.errors.pattern">Current Password must be valid</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-4 ">
        <div class="col-md-5 col-12 pass-fields-lebal">Enter new password :</div>
        <div class="col-md-7 col-12">
          <div class="password-container position-relative">
            <input formControlName="newPassword" [type]="showHidePassword ? 'text' : 'password'"
              placeholder="type in your password" class="ch-nge-pass-input"
              [ngClass]="{ 'is-invalid': submitted && f.newPassword.errors }" />
            <div class="password_icon">
              <img src="../../../assets/images/hide-password.png" alt="" (click)="showHidePassword = !showHidePassword"
                [class.hide]="showHidePassword" />
              <img src="../../../assets/images/password.png" alt="" (click)="showHidePassword = !showHidePassword"
                [class.hide]="!showHidePassword" />
            </div>
            <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
              <div *ngIf="f.newPassword.errors.required">New Password is required</div>
              <div *ngIf="f.newPassword.errors.pattern">New Password must be valid</div>
            </div>
            <div class="small-line">Use an uppercase character, number and special character.</div>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-5 col-12 pass-fields-lebal">Re-enter password :</div>
        <div class="col-md-7 col-12">
          <input formControlName="confirmPassword" type="password" placeholder="" class="ch-nge-pass-input"
            [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />

          <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
            <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
            <div *ngIf="f.confirmPassword.errors.pattern">Confirm Password must be valid</div>
            <div *ngIf="f.confirmPassword.errors.mustMatch">Password must be match</div>
          </div>
        </div>
      </div>
      <div class="btnnnnnn">
        <button type="submit" class="btnn contact-btn">Change password</button>
      </div>
    </div>
  </div>
</form>
