<div class="payment-detail-container">
    <div class="user-profile-main-heading">Payment details</div>
    <div class="mt-5">
      <div class="credit-debits">Saved cards <span> and debit cards</span></div>
      <div class="visa-container-flex"><img src="assets/images/visa-blue.png" alt="">
        <div> Visa ending in 2635 </div> <img src="assets/images/arrow-outline.png" alt="" class="down-arrow-img">
      </div>
      <div class="playpal-id-container">
        <div class="paypal-id-text">
          Paypal ID
        </div>
        <div class="direction-inverse">
          <input type="text" placeholder="samantha72@outlook.com">
          <div class="edit-box"><img src="assets/images/edit-blue.png" alt="">
            &#160;&#160; Edit
          </div>
        </div>
      </div>
    </div>
  </div>
